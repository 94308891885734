import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import OneMember from './OneMember';

function OurTeam() {
  const team = [
    { 
      name: "Birndze Bete Dzekewong", 
      position: "Founder and CEO", 
      image: require("../img/team/Team/Birndze Bete Dzekewong - Founder and CEO.png"),
      bio: "Birndze Bete is a West African social entrepreneur, SDG champion, and youth activist known for his dedication and commitment to supporting young people in underserved communities. He focuses on providing access to education, skills, and opportunities for them to become community leaders who can turn their ideas and passion into innovative social projects and businesses. Birndze firmly believes that even small actions such as smiling, volunteering, or supporting others can make a significant difference in someone’s life. Birndze's dedication to enhancing education and promoting skills development for youth has led him to serve in various capacities, including being the Working Group Leader for YOUNGA Delegates 2023 on policy development for the future of work and education, volunteering as the Queen's Commonwealth Trust Network Hub Communication Lead, and serving as a Peace First Ambassador.",
    },
    { name: "Temitope Lawal", position: "Programs Asssociate", image: require("../img/team/Team/Temitope Lawal - Programs Asssociate.png") },
    { 
      name: "Abongke Claire", 
      position: "Finance Manager", 
      image: require("../img/team/Team/Abongke Claire - Finance Manager.png"),
      bio: "",
    },
    { name: "Beltus Nkwawir", position: "Mentorship Coordinator", image: require("../img/team/Team/Beltus Nkwawir - Mentorship Coordiantor.png") },
    { name: "Birla Charity Youngyeh", position: "Programs manager", image: require("../img/team/Team/Birla Charity Y. - Programs lead.png") },
    { name: "Kudakwashe Foya", position: "Partnership and proprams development manager", image: require("../img/team/Team/Kudakwashe Foya - Partnership and proprams development manager.png") },
    { name: "Njodzeka Claudia Berinyuy", position: "Community Engagment officer", image: require("../img/team/Team/Njodzeka Claudia Berinyuy - Community Engagment officer.png") },
    { name: "Bamnjo Marilyn Leinyuy", position: "Administrative Assistance", image: require("../img/team/Team/Bamnjo Marilyn Leinyuy - Administrative Assistance.png") },
    { name: "Jik Alvin", position: "Technology Integration Coordinator", image: require("../img/team/Team/Technology Integration Coordinator.png") },
  ]
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h2 className="text-center mb-5">Staff</h2>
        </Col>
      </Row>
      <Row>
        {team.map((member, index) => <OneMember key={index} member={member} />)}
      </Row>
    </Container>
  );
}

export default OurTeam;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import OneMember from './OneMember';

function OurTeam() {
    const team = [
        {
          name: "Alex Benshakov", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Alex Balashov - Advisor.png"),
          bio: "Alex Balashov is a people-oriented professional with over 20 years of legal and teaching experience, which he brings to YOCILAB. He is the author of and contributor to several publications, including the EU Merger Decisions Digest. Alex is an ICF Certified Business Coach (ACC), trainer, and speaker. He is passionate about ecopreneurship and is dedicated to supporting established businesses and startups in developing and implementing Sustainable Business Models and ESG policies"
        },
        {
          name: "Salsabila Nurhidajat", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Salsabila Nurhidajat - Advisor.png"),
          bio: "Salsabila Nurhidajat is a RIVET.org Fellow and an Indonesian-American media and communications professional in Dubai. With a background in Political Science and Cinema & Media Arts, she uplifts underrepresented voices through storytelling and media production. Recently with Solidarity 2020 and Beyond, she's worked with NGOs worldwide."
        },
        {
          name: "Dr. Eng. Godlove Suila Kuaban", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Dr. Eng. Godlove Suila - Training Coridnator.png"),
          bio: "Dr. Eng. Godlove is a researcher at the Institute of Theoretical and Applied Informatics – Polish Academy of Sciences. Since October 2017, he has been an Assistant Lecturer at the Faculty of Automatic Control, Electronics, and Computer Sciences, Institute of Computer Science, Silesian University of Technology, Poland. "
        },
        {
          name: "Jasen Booton", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Jasen Booton - Adisor.png"),
          bio: "Jasen has worked in early grade education for over twenty-five years, as an Advanced Skills Teacher, senior leader, governor and founding director of an Academy Trust group of schools.  He has a breadth of coaching and mentoring experience in a wide range of international educational settings with a reputation for empowering teachers and school leaders to succeed, often in challenging contexts. Jaen is currently supporting the ministry of education in the Kingdom of Tonga to implement a World Bank funded project – Innovative curriculum. He has also worked closely with educators in Ghana, focusing on the development of foundational language and literacy skills in rural schools. He also coaches leaders in education in Sierra Leone and is excited to be supporting young voices to rise in Cameroon. For him, ‘partnership principles’ are fundamental when supporting growth of leaders and learners in Sub-Saharan Africa."
        },
        {
          name: "Jasmin Cha", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Jasmin Cha - Advisor.png"),
          bio: "Jasmine cha is an advocate for peace, justice, and a recognized member of Alberta's Top 30 Under 30 for her unwavering commitment to reducing inequality around the world. Known as a Digital Changemaker and Social Troubleshooter, she dedicates herself to leveraging her skills for genuine impact. From resolving tech challenges to devising innovative solutions for social issues, Jasmine actively pursues positive change in her community and on a global scale."
        },
        {
          name: "Kudakwashe Foya", 
          // position: "Board of Advisors", 
          image: require("../img/team/Team/Kudakwashe Foya - Partnership and proprams development manager.png"),
          bio: "Kudakwashe Foya is a youth empowerment enthusiast, with 7 years of experience in the educational and professional development space as a teacher, mentor, researcher, and co-founder. She started teaching at 19 years a career that spanned for four years. This translated into mentoring social impact entities at events like the MIT Grand Hackathon, Peace First and Merge Policython, mentoring impact projects solving global challenges in health and education. She co-founded a social impact entity called The LA Initiative which works to democratize educational and professional development opportunities through Open classes  and training, and Mentorship opportunities through engaging experts from different parts of the world to allow young people to leverage their experiences to help establish themselves. As a young researcher she co-authored a book called ' ‘Empowering young voices'' through a  research fellowship with  Say What, a program funded by the Swedish Embassy in Zimbabwe. She researched the effects of sudden systemic changes like that caused by COVID-19 on the educational access and routines of students living with disabilities. This research is hoped to build a knowledge repository that will influence national policies in the future on how to better respond to sudden systemic changes .  Consequently, she has received recognition as a social impact leader from The Emy Africa awards as a nominee for the youth change makers award, The Varkey Foundation and Cheggdotorg as a Global Student PrizeFinalist, The Diana Award, UNICEF Innocenti, Aspire Institute,  Crisis UK and recently as a recipient of The African Women In Development Award by Donors For Africa."
        },
    ]
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h2 className="text-center mb-5">Trustees</h2>
        </Col>
        <Col>
          <p className='mb-5'>The board of trustees leads and is accountable for the work of YOCILAB. It sets the strategy
             approves the brand identity values and the annual business plan and budget. Trustees provide
             their expertise and time without charge.
          </p>
        </Col>
      </Row>
      <Row>
        {team.map((member, index) => <OneMember key={index} member={member} />)}
      </Row>
    </Container>
  );
}

export default OurTeam;

import React , {useState} from "react";
import { useParams} from 'react-router-dom';
import fb from "../../firebase";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "../BlogsList.css"

const DB =fb.firestore()
const Blogslist = DB.collection('blogs');

const BlogView = ()=> {
    const {id} = useParams();
    const[blogs, Setblogs] = useState([]);
    Blogslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setblogs(data);
    });
    return(
        <div>
            <Navbar />
            <div className="cont1">
            <div
                className="py-5"
            >
                <p className="fs-2 fw-bold title1">{ blogs.Title}</p>
                <p className="body">{blogs.Body}</p>
                <div className="imgCont">
                    <img src={blogs.image} style={{width: "100%"}} alt="Image" />
                </div>
            </div>
            </div>
            <Footer />
        </div>
    );
};
export default BlogView;
import React from 'react';
import './SocialInnovatorsFellowship.css';

const SocialInnovatorsFellowship = () => {
  return (
    <div className="sif-section">
      <h1 className="section-title">Social Innovators Fellowship (SIF)</h1>
      <p className="section-description">
        Empowering Change-Makers to Drive Social Impact. The Social Innovators Fellowship (SIF) is a six-month, project-based program designed to support young leaders and founders of youth-led social impact organizations aged 18 to 30. This fellowship empowers participants to create innovative solutions to community challenges.
      </p>

      <div className="sif-content">
        <div className="sif-image-container">
          <img src={require("../../img/cocreation/present.jpg")} alt="Social Innovators Fellowship" />
        </div>
        <div className="sif-info">
          <p>
            SIF provides essential resources, mentorship, and a collaborative environment to help fellows transform their ideas into impactful projects.
          </p>

          <h3>Fellowship Benefits</h3>
          <ul>
            <li><strong>Mentorship:</strong> Pairing with experienced mentors from various sectors to guide your project development.</li>
            <li><strong>Workshops and Training:</strong> Participate in skill-building workshops covering topics like project management, fundraising, and impact measurement.</li>
            <li><strong>Networking Opportunities:</strong> Connect with fellow innovators, industry leaders, and potential collaborators.</li>
            <li><strong>Access to Resources:</strong> Utilize YOCILAB’s co-working space, tools, and materials to develop your project.</li>
            <li><strong>Funding:</strong> Pitch your idea to potential funders and access seed funding to kickstart or scale your social innovation project.</li>
          </ul>

          <p>Applications for the next cohort of the Social Innovators Fellowship (SIF) open on April 1, 2025. Sign up for our newsletter to be the first to know when applications are live!</p>
          <a href="#" className="btn apply-btn btn-primary">Apply</a>
        </div>
      </div>
    </div>
  );
};

export default SocialInnovatorsFellowship;

import React from "react";
import { Col } from "react-bootstrap";

export default function({ member }){
    return(
        <Col md={4}>
          <div className="team-member">
            <img src={member.image} alt="Team Member" className="img-fluid" />
            <h4>{member.name}</h4>
            <p className="text-muted">{member.position}</p>
            <div className="team-member-info">
            {member.bio && <p> {member.bio} </p>}
            </div>
          </div>
        </Col>
    )
}
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


export default function(){
    return(
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: "url(../img/yocilab/Core.jpg)"}}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">PoolFund</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">PoolFund</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// The Challenge   //</h6>
                            <p class="mb-4">
                                More than 95% of the brilliant minds we support lack the necessary investment and funding to bring their game-changing ideas to life. 
                            </p>
                            <p class="mb-4">
                                These young innovators have the power to drive economic development in their local communities and beyond, but they need our help to make it happen.
                            </p>
                        </div>
                        <div class="col-lg-6 pt-4" style={{mineight: "400px"}}>
                            <div class="position-relative h-100 wow fadeIn p-0" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/second/xcr.jpg" style={{objectFit: "cover"}} alt=""/>
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                                    <h1 class="display-4 text-white mb-0"></h1>
                                    <h4 class="text-white"></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 pt-4" style={{minHeight: "400px"}}>
                            <div class="position-relative h-100 wow fadeIn p-0" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/yocilab/IMG-20220909-WA0051.jpg" style={{objectFit: "cover"}} alt=""/>
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                                    <h1 class="display-4 text-white mb-0"></h1>
                                    <h4 class="text-white"></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Our Solution   //</h6>
                            <p class="mb-4">
                                More than 95% of the brilliant minds we support lack the necessary investment and funding to bring their game-changing ideas to life. 
                            </p>
                            <p class="mb-4">
                                PoolFund connects trusted entrepreneurs and change-makers in our programs with funders and investors who recognize and support the transformative potential of these innovative solutions. Through our dynamic community of like-minded individuals, we help bring visionary ideas to life, fostering the progress and growth that spurs real change in the world
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid bg-secondary booking my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-lg-6 py-5">
                            <div class="py-5">
                                <h1 class="text-white mb-4">Get To Us</h1>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="bg-primary h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
                                <h1 class="text-white mb-4">Contact Us</h1>
                                <form>
                                    <div class="row g-3">
                                        <div class="col-12 col-sm-6">
                                            <input type="text" class="form-control border-0" placeholder="Your Name" style={{height: "55px"}} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input type="email" class="form-control border-0" placeholder="Your Email" style={{height: "55px"}} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <select class="form-select border-0" style={{height: "55px"}}>
                                                <option selected>Select An Option</option>
                                                <option value="1">I have a project</option>
                                                <option value="2">I want to Fund</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="date" id="date1" data-target-input="nearest">
                                                <input type="text"
                                                    class="form-control border-0 datetimepicker-input"
                                                    placeholder="Service Date" data-target="#date1" data-toggle="datetimepicker" style={{height: "55px"}}/>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control border-0" placeholder="Write something"></textarea>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-secondary w-100 py-3" type="submit">Send</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
import "./BlogsList.css"
import { extractWordsFromString } from "../utils"

export default function({ title, text, linkv, linke, DeleteBlog, id, image }){
    return(
        <>
            <div class="card my-3 box">
            <img class="card-img-top" src={image} alt="Card image cap" height={"350px"} />
            <div class="card-body">
                <h5 class="card-title">{title}</h5>
                <p class="card-text">{extractWordsFromString(text, 15)} ...</p>
                <div style={{display: "flex", 
                        flexDirection: "row", 
                        justifyContent:"space-between", 
                        alignItems:"center"}}>
                    <a href={linkv}>View</a>
                    {/* <a href={linke}>Edit</a> */}
                </div>
            </div>
            </div>
        </>
    )
}
export function extractWordsFromString(str, numWords) {
    // Split the string into an array of words
    var wordsArray = str.split(' ');
  
    // Extract the desired number of words using slice
    var extractedWords = wordsArray.slice(0, numWords);
  
    // Join the extracted words back into a string
    var result = extractedWords.join(' ');
  
    return result;
  }
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function HomePage() {
    return (
        <div>
            <Navbar />

            {/* Hero Section */}
            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img/yocilab/IMG-20220909-WA0051.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">YOCILAB Academy</h1>
                        <p className="text-white lead">Bridging Education and Employment Gap</p>
                    </div>
                </div>
            </div>

            {/* TM2 Platform Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row align-items-center g-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4 text-primary">TM2 Platform</h2>
                            <h4>The Journey Starts Here!</h4>
                            <p>YOCILAB's Talent Management and Match-Up Platform (TM2) is a one-stop space for young people seeking opportunities, guidance, support, and invaluable insights to advance their careers and drive meaningful change.</p>
                            <p>Our AI model provides personalized career guidance, matches users with opportunities tailored to their career needs, and streamlines the recruitment process for companies by connecting them with top talent. Additionally, we support universities in managing students' professional internships.</p>
                            <p><strong>Are you looking for a career development tool, opportunities, talent, mentors, or mentees? TM2 is your go-to platform!</strong></p>
                            <a href="#" className="btn btn-primary py-2 px-4">Learn more and sign up</a>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/academy/Tech Skills.jpg")} alt="TM2 Platform" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Young Graduates Fellowship Section */}
            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/academy/YOung graduates fellowship.png")} alt="Young Graduates Fellowship" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="mb-4 text-primary">Young Graduates Fellowship (YGF)</h2>
                            <p>The YOCILAB Young Graduate Fellowship is a 4-week virtual program plus a one-week onsite co-creation boot camp with the goal of bridging the gap between education and the evolving job market. We empower young graduates and final-year students ages 18 to 29 in Cameroon with training, mentorship, and networking opportunities, equipping them with essential skills in entrepreneurship, design thinking, mental health, and employment readiness.</p>
                            <p><strong>Application for the next cohort of the YGF opens on the 1st of September 2024.</strong></p>
                            <a href="https://forms.gle/ksReYCvefwc1Q1Lz5" className="btn btn-secondary py-2 px-4">Apply here</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* YOCILAB Skill Hub Section */}
            <div className="container-xxl py-5">
                <div className="container text-center">
                    <h2 className="text-primary mb-5">YOCILAB Skill Hub</h2>
                    <p className="lead mb-5">Empowering Youth Through Targeted Skill Development</p>
                    <div className="row g-4">
                        <div className="col-lg-4">
                            <div className="h-100 border-0 shadow">
                                <div className="card-body">
                                    <h4 className="text-primary mb-4">Fashion Design</h4>
                                    <p>Our Fashion Design program nurtures creativity and innovation, guiding aspiring designers through the fundamentals of the fashion industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="h-100 border-0 shadow">
                                <div className="card-body">
                                    <h4 className="text-primary mb-4">Entrepreneurship</h4>
                                    <p>Our Entrepreneurship program is designed for those who aspire to be leaders and innovators. We provide the tools and knowledge necessary to transform ideas into successful social ventures and businesses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="h-100 border-0 shadow">
                                <div className="card-body">
                                    <h4 className="text-primary mb-4">Tech Skills</h4>
                                    <p>In an increasingly digital world, tech skills are essential for success in any field. Our Tech Skills program focuses on equipping participants with the knowledge and tools needed to thrive in technology-driven environments.</p>
                                    <a href="/dti" className="btn btn-primary py-2 px-4">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Join Us Section */}
            <div className="container-xxl py-5 bg-light text-center">
                <div className="container">
                    <h2 className="text-primary mb-4">Join Us!</h2>
                    <p className="lead mb-4">We are providing solutions to youth unemployment by empowering youth through skill training that prepares them for successful careers.</p>
                    <p className="lead">Whether you’re interested in fashion design, entrepreneurship, or tech skills, our programs offer the guidance and resources you need to achieve your goals.</p>
                    <p><strong>Application for the next training cohort opens in March 2025. Sign up now and be the first to know when our application opens.</strong></p>
                    <a href="#" className="btn btn-primary py-2 px-4">Become a partner</a>
                    <a href="#" className="btn btn-secondary py-2 px-4 ms-3">Donate</a>
                </div>
            </div>

            {/* Contact Section */}
            <div className="container-xxl py-5">
                <div className="container text-center">
                    <h3 className="text-primary mb-4"><i className="fa fa-phone-alt me-3"></i><a style={{ color: "#000" }} href="https://wa.me/237680192095">+237680192095</a></h3>
                    <a href="#" className="btn btn-secondary py-3 px-5">Contact Us<i className="fa fa-arrow-right ms-3"></i></a>
                </div>
            </div>

            <Footer />
        </div>
    );
}

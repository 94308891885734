import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


export default function(){
    return(
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: "url(../img/yocilab/IMG-20220909-WA0051.jpg)"}}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Inside YOCILAB</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Yocilab</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Our Story //</h6>
                            <h1 class="mb-4"><span class="text-primary">We</span> Are YOCILAB</h1>
                            <p class="mb-4">Youth Connect Innovation Lab (YOCILAB) serves as an entrepreneurship ecosystem that enables young people to establish sustainable businesses, access opportunities, develop valuable connections, and foster innovation. Our platform offers a collaborative workspace for various startups, along with programs that promote career and educational growth for youths in marginalized communities.
                            </p>
                            <p class="mb-4">Over 70% of youths in underserved communities in Cameroon and Sub-Saharan Africa live below the poverty line of less than a dollar daily, and with high rate of youth unemployment. In these communities where opportunities are scant for young people, we endeavor to foster an environment that promotes inclusivity and enables youths from disadvantaged communities to design systematic solutions to address local community challenges.</p>
                    </div>
                    <div class="col-lg-6 pt-4" style={{minHeight: "400px"}}>
                        <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                            <img class="position-absolute img-fluid w-100 h-100" src="./img/yocilab/Head shoot Birndze Bete.jpg" style={{objectFit: "cover"}} alt=""/>
                            <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <Footer />
        </div>
    )
}
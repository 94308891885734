import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function DonatePage() {
    return (
        <div>
            <Navbar />
            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img/yocilab/IMG-20220909-WA0051.jpg)" }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">Support Our Cause</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Donate</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container text-center">
                    <h2 className="mb-4">Your Support Makes a Difference</h2>
                    <p className="mb-4">
                        Your generous donation helps us bridge the digital literacy gap and provide essential technology education to marginalized communities. Join us in making a positive impact.
                    </p>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=SHXZ5AGMC67QJ" className="btn btn-primary btn-lg" target="_blank" rel="noopener noreferrer">
                        Donate with PayPal
                    </a>
                </div>
            </div>

            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <img src="./img/donate/computer.jpg" className="card-img-top" alt="Donate Computers" />
                                <div className="card-body">
                                    <h5 className="card-title">Donate Computers</h5>
                                    <p className="card-text">
                                        Help us equip young people with the necessary tools by donating new or used laptops and computers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <img src="./img/donate/tech_training.jpg" className="card-img-top" alt="Sponsor Tech Training" />
                                <div className="card-body">
                                    <h5 className="card-title">Sponsor Tech Training</h5>
                                    <p className="card-text">
                                        Fund our training programs that provide essential ICT and STEM education to underprivileged youths.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card h-100">
                                <img src="./img/donate/community_programs.jpg" className="card-img-top" alt="Support Community Programs" />
                                <div className="card-body">
                                    <h5 className="card-title">Support Community Programs</h5>
                                    <p className="card-text">
                                        Contribute to our community programs that foster innovation and creativity among young people.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </div>
    );
}

export const blogs = [
    {
        tag: "Breaking Barriers",
        title: "YOCILAB Collaborates with College of Technology to train over 300 university students on Entrepreneurship",
        hook: "YOCILAB organized a one-day entrepreneurship training in collaboration with the College of Technology",
        content: [
            "Over 75% of young graduates and students in Cameroon face challenges entering the competitive job market due to limited practical entrepreneurial skills. Despite having ideas, 9 in 10 of them lack the skills and training to transform these ideas into sustainable businesses. This often leads to difficulties in securing employment or starting their own ventures, resulting in high rates of youth unemployment, economic hardship, and social community challenges.",
            "Recognizing this issue, YOCILAB organized a one-day entrepreneurship training in collaboration with the College of Technology – University of Bamenda, Cameroon to provide practical training in entrepreneurship and leadership to over 300 university students with the aim to bridge the gap between academic learning and real-world application, and to empower students to become entrepreneurs and leaders solving pressing challenges.",
            "Speaking at the training program, YOCILAB Founder and Executive Director Birndze Bete Dzkewong encouraged students to consider entrepreneurship and not wait on the government for employment. He stated, “We have the power to transform our lives and our community, but we have to take action today and not leave our future in the hands of older people that do not understand our challenges.”",
            "The Director of the University thanked the YOCILAB team, called for more collaboration, and encouraged students to put the learning into practice and become visionaries that will change their communities. Several feedbacks from students expressing their gratitude, thoughts, and challenges were also received. Sandra, one of the students, remarked, “We have ideas but what is lacking is the right support. Even when you want to start, you do not have the people to support you or the funding to start the business.”",
            "Most of the challenges expressed by the students align with solutions provided by our current programs: YOCILAB Academy, YOCILAB co-creation space, and Seed4Impact fund. As we move forward, we are committed to helping more youth bring their ideas to life.",
        ],
        photo: require("./img/blog/collaboration with Coltech.jpg"),
    },
    {
        tag: "Yocilab",
        title: "YOCILAB Team join Panel Discussion at Youth Forum on Adaptation Finance in Africa",
        hook: "Our team joined and took center stage at the Inaugural Youth Forum On Adaptation Finance in in Yaounde, Cameroon",
        content: [
            "Our team joined and took center stage at the Inaugural Youth Forum On Adaptation Finance in in Yaounde, Cameroon. We were thrilled to join over 150 young leaders from 30 African countries under the theme Empowering Africa: More Than Doubling Adaptation Finance for a Resilient Future.",
            "During the forum, our youth engagement officer, Ms. Njodzeka Cluadia, joined the panel discussion to share our belief in the power of young people to lead change and emphasize the importance of empowering youth to play a key role in driving sustainable and resilient financial mechanisms for climate adaptation. Her contributions emphasized the vital role of young leaders in shaping the future of adaptation finance in Africa and our belief in youth as leaders of today.",
            "As the forum ended, we were happy to have contributed to the drafting of an adaptation finance proposal that prioritized the specific needs of African countries, while calling on developed nations to fulfill their commitment by more than doubling the adaptation finances to Africa.",
        ],
        photo: require("./img/blog/Youth forum on adaptability and finance in africa.jpg"),
    },
    {
        tag: "Yocilab",
        title: "YOCILAB Founder Takes Lead Role at YOUNGA 2023",
        hook: "The largest global festival for impact organized by BridgingTheGap Ventures",
        content: [
            "YOUNGA® 2023, the largest global festival for impact organized by BridgingTheGap Ventures, saw the active participation of YOCILAB's Founder and Executive Director, Birndze Bete Dzkewong. Selected as working group leader for the future of work and education, Dzkewong led changemakers from more than 150 countries in crafting policy recommendations for the future of work and education as part of the YOUNGA communique. ",
            "Dzekewong has great passion for bridging the skill gap between the vibrant African youth and the ever-evolving industrial landscape and his leadership at YOUNGA 2023 is a testament to his commitment to drive innovative solutions for the evolving world of work and education and his belief in the power of young people to become key actors in development. With Dzkewong's influential leadership, YOCILAB continues to be at the forefront of driving innovation and positive transformation in the realms of work, education, and sustainable development",
        ],
        photo: require("./img/blog/YOUNGA 2023.png"),
    },
    {
        tag: "Bridging the Gap",
        title: "YOCILAB organized tailored Training for young Female Entrepreneurs",
        hook: "When women and girls are empowered to become problem solvers, we transform not just their lives but our communities",
        content: [
            "In recent years, there has been a growing recognition of the importance of diversity and inclusion in entrepreneurship. However, it is no secret that women continue to face unique challenges in the business world. To address this disparity and empower more female youth entrepreneurs, YOCILAB organized a tailored skills training program. The workshop featured focus group discussions, masterclasses, business pitches, and lectures, bringing together 30 young female entrepreneurs in the North West Region of Cameroon. They were drilled on the following topics:",
            "- Building successful businesses and identifying opportunities for growth- Branding and brand positioning- Fundraising and financial management",
            "During the training, the entrepreneurs formed a support network to share knowledge and resources and created a Women Revolving Financial Scheme (WRFS) to support and finance youth-led women start-up businesses, which is currently under development.",
            "Speaking after the event, Clotilda, one of the participants, had this to say: \"This is one of the most insightful trainings that I have attended. I use the knowledge and skills to grow my business. My hope is to see the Women Revolving Financial Scheme materialized.\"",
            "Another participant, Valerie, also shared her thoughts: \"I just came here because a friend invited me, but I am happy that I did. I love the way the training was delivered, and will use the knowledge in starting my own business soon. ",
            "From the training, it was clear that investing in successful women entrepreneurship is not only the right thing to do but also the smartest decision.",
        ],
        photo: require("./img/team/training.jpg"),
    },
    {
        tag: "Yocilab",
        title: "YOCILAB Launches Youth Entrepreneurship Co-Creation Lab",
        hook: "One of the greatest challenges for young entrepreneurs is a support network and a space to develop their business ideas",
        content: [
            "One of the greatest challenges for young entrepreneurs is a support network and a space to develop their business ideas. Most often, they do not have the financial resources to secure an office space and are surrounded by naysayers. To support young entrepreneurs in their journey, the YOCILAB Team successfully launched its entrepreneurship co-creation lab in the Bamenda - North West region of Cameroon on the 11th of June 2023 to provide a creation space and a platform to nurture youth ideas and untapped potentials.",
            "The YOCILAB Co-Creation Lab aims to provide an inclusive vibrant community and a collaborative space where emerging leaders and aspiring young entrepreneurs can cultivate their ideas, receive 260- degree support, and gain practical experience in launching and managing their own ventures. By fostering a supportive and dynamic environment, YOCILAB seeks to nurture the next generation of business leaders and innovators.",
        ],
        photo: require("./img/blog/women entrepreneurship progra.jpg"),
    },
    
]
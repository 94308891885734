import React from 'react';
import './hero.css'

const Hero = () => {
  return (
    <div class="container-xxl py-5 mb-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
              <img class="position-absolute img-fluid w-100 h-100" src={require("../../img/new/8.png")} style={{ objectFit: "cover" }} alt="" />
              {/* <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                <h1 class="display-4 text-white mb-0">5 <span class="fs-4">Years</span></h1>
                <h4 class="text-white">Experience</h4>
              </div> */}
            </div>
          </div>
          <div class="col-lg-6">
            {/* <h6 class="text-primary text-uppercase">// Our Mission //</h6> */}
            <h1 class="mb-4"><span class="text-primary">Transforming</span> Mindsets for Social Impact  </h1>
            <p class="mb-4">
            YOCILAB provides learning opportunities, access to resources, and empowers young people to transform their visions and ideas into social enterprises, businesses, organizations, and movements that create sustainable impact.  
            </p>
            <p class="mb-4">
              Whether you come from different backgrounds or communities, our mission is to inspire and support you towards your goals. Join us in transforming your vision into reality and creating a better future for yourself and the world around you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
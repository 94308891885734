// src/components/HomePage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import Hero from '../components/home/HeroSection';
import WhyWeExist from '../components/home/WhyWeExist';
import WhatWeDo from '../components/home/WhatWeDo';
import OurImpact from '../components/home/OurImpact';
import HearFromCommunity from '../components/home/HearFromCommunity';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import StatisticsComponent from '../components/home/WhyWeExist';
import JoinCommunity from '../components/home/JoinCommunity';
import BlogCard from '../components/BlogCard';
import { Carousel } from 'react-bootstrap';
import { blogs } from '../constants';

const HomePage = () => {
  return (
    <div className="homepage">
      <Navbar />
      {/* <Hero /> */}

      <div class="container-fluid p-0 mb-5">
        <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" style={{ height: "100vh" }}>
              <img class="w-100" src="../img/yocilab/digital tech initiative.JPG" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">// Digital Tech Initiative //</h6>
                      <h1 class="display-3 text-white mb-4 pb-3 animated slideInDown" style={{ fontSize: "xx-large" }}>Bridging IT literacy gap in marginalised communities</h1>
                      <a href="https://www.paypal.com/donate/?hosted_button_id=SHXZ5AGMC67QJ" class="btn btn-primary py-3 px-5 animated slideInDown">Donate<i class="fa fa-arrow-right ms-3"></i></a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      {/* <!-- <img class="img-fluid" src="./img/main-slider/2348154580684_status_0dd199cbc8924b599fc224f8e6d7568f.jpg" alt=""> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" style={{ height: "100vh" }}>
              <img class="w-100" src="../img/yocilab/startup hive.jpg" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">// YOCILAB Co-creation Space //</h6>
                      <h4 style={{ fontSize: "xx-large" }} class="display-3 text-white mb-4 pb-3 animated slideInDown">
                        At the YOCILAB Co-creation Space, we provide a home for the brightest minds and boldest ideas to strive.
                      </h4>
                      <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      {/* <!-- <img class="img-fluid" src="img/carousel-2.png" alt=""> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" style={{ height: "100vh" }}>
              <img class="w-100" src="../img/yocilab/a1.png" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">// Yocilab Academy //</h6>
                      <h1 style={{ fontSize: "xx-large" }} class="display-3 text-white mb-4 pb-3 animated slideInDown">Helping young professionals and students take charge of their futures!</h1>
                      <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      {/* <!-- <img class="img-fluid" src="img/carousel-2.png" alt=""> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" style={{ height: "100vh" }}>
              <img class="w-100" src="../img/yocilab/Impact Space cover .jpg" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">// Social Impact Space //</h6>
                      <h1 style={{ fontSize: "xx-large" }} class="display-3 text-white mb-4 pb-3 animated slideInDown">Get inspired by real stories from entrepreneurs and ChangeMakers in action.
                      </h1>
                      <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      {/* <!-- <img class="img-fluid" src="img/carousel-2.png" alt=""> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" style={{ height: "100vh" }}>
              <img class="w-100" src="../img/yocilab/PXL_20220825_093222192~3.jpg" alt="Image" />
              <div class="carousel-caption d-flex align-items-center">
                <div class="container">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <div class="col-10 col-lg-7 text-center text-lg-start">
                      <h6 class="text-white text-uppercase mb-3 animated slideInDown">// Seed4Impact Fund //</h6>
                      <h1 style={{ fontSize: "xx-large" }} class="display-3 text-white mb-4 pb-3 animated slideInDown">Investing in high impact youth organisations and social enterprises
                      </h1>
                      <a href="" class="btn btn-primary py-3 px-5 animated slideInDown">Learn More<i class="fa fa-arrow-right ms-3"></i></a>
                    </div>
                    <div class="col-lg-5 d-none d-lg-flex animated zoomIn">
                      {/* <!-- <img class="img-fluid" src="img/carousel-2.png" alt=""> --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <Hero />

      <StatisticsComponent />
      <WhatWeDo />
      <OurImpact />
      <HearFromCommunity />
      <JoinCommunity />
      <Footer />
    </div>
  );
};

export default HomePage;
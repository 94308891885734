import React , {useState} from "react";
import fb from "../../firebase";
import "firebase/compat/storage"
import { useNavigate } from "react-router-dom";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage"

const DB =fb.firestore()
const storage = fb.storage()
const Blogslist = DB.collection('blogs');

const CreateBlog = () => {
    const navigate = useNavigate()

    const [title, SetTitle] = useState("");
    const [body, SetBody] = useState("");
    const [image, SetImage] = useState("");
    const [i, setI] = useState()
    const [loading, setLoading] = useState(false)

    const submit = async (e)=> {
        e.preventDefault();
        setLoading(!loading)
        if(!image) return;

        const imageRef = storage.ref(`blog/${image.name}`);

        await imageRef.put(image)
        const downloadURL = await imageRef.getDownloadURL();
        console.log(title, body, downloadURL)

        Blogslist.add ({
            Title: title,
            Body: body,
            image: downloadURL,
        }).then((docRef)=> {
            alert("data successfully submit")
        }).catch((error) => {
            console.error("error:", error);
        });
        setLoading(!loading)
        navigate("/blog")
    }
    return(
        <div>
            {/* <form onSubmit={(event) => {submit(event)}}>    
            <input type="text" placeholder="Title" 
            onChange={(e)=>{SetTitle(e.target.value)}} required />

            <textarea  name="content" type="text" placeholder="write your content here" 
            rows="10" cols="150" onChange={(e)=>{SetBody(e.target.value)}} required >
            </textarea>

            <button type="submit">Submit</button>
            </form> */}
            <div class="container">
                <div class=" text-center mt-5 ">

                    <h1 >Create Post</h1>
  
                </div>
            <div class="row ">
            <div class="col-lg-7 mx-auto">
                <div class="card mt-2 mx-auto p-4 bg-light">
                    <div class="card-body bg-light">
            
                    <div class = "container">
                    <form id="contact-form" role="form" onSubmit={(event) => {submit(event)}}>
                    <div class="controls">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="form_name">Title *</label>
                                    <input type="text" onChange={(e)=>{SetTitle(e.target.value)}} required class="form-control" placeholder="Enter the title *" data-error="Title is required."/>
                                    
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="form_lastname">Image *</label>
                                    <input onChange={(e)=>{SetImage(e.target.files[0])}} type="file"class="form-control" placeholder="Choose a picture*" required="required" data-error="Lastname is required."/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="form_message">Message *</label>
                                    <textarea class="form-control" placeholder="Write your message here." rows="4" required="required" data-error="Message boddy." onChange={(e)=>{SetBody(e.target.value)}}></textarea>
                                    </div>

                                </div>


                            <div class="col-md-12">
                        </div>
                
                        </div>
                </div>
                <input type="submit" class="btn btn-success btn-send  pt-2 btn-block" value={loading ? "Loading" : "Post"} />
                </form>
                </div>
                    </div>


            </div>

            </div>

        </div>
        </div>
        </div>
    );
};

export default CreateBlog;
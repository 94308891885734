import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BlogDisplay from "../components/BlogDisplay"
import { useLocation } from "react-router-dom";


export default function(){
    const {state} = useLocation()
    const blog = state || {}
    console.log(blog)

    return(
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: "url(../img/yocilab/Core.jpg)"}}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Blog</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Blog</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <BlogDisplay blog={blog} />

            <Footer />
        </div>
    )
}
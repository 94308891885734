import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import TeamCarousel from "../components/TeamCarousel"
import Staff from "../components/Staff"

export default function Approach() {
    return (
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img//yocilab/IMG-20220909-WA0051.jpg)" }}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Our Approach</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Our Approach</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Intro //</h6>
                            <h1 class="mb-4"><span class="text-primary">At</span> YOCILAB</h1>
                            <p class="mb-4">
                            At YOCILAB, we are true believers in the potential of young people to effect meaningful change in their communities. That's why we are committed to providing the tools, skills, and support needed to design sustainable solutions to the most pressing community challenges. Our IDI systematic ChangeMakers model includes three core components:
                            </p>
                        </div>
                        <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src={require("../img/new/4.png")} style={{ objectFit: "cover" }} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                {/* <!-- Service Start --> */}
                <div class="container-xxl py-5 mt-5">
                    <div class="container">
                        <h1 class="text-primary text-uppercase" style={{ fontSize: "large" }}>// Our Approach //</h1>
                        <div class="row g-4">
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="d-flex py-5 px-4">
                                    <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Identifying community challenges</h5>
                                        <p>
                                        We help you to hone in on the most pressing issues facing your community, so you can develop targeted solutions that address the root cause of the problem.
                                        </p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div class="d-flex bg-light py-5 px-4">
                                    <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Creating user-centric sustainable solutions</h5>
                                        <p>
                                        We guide you through the process of designing tailored, sustainable solutions that are specific to the challenges you're working to address.
                                        </p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div class="d-flex py-5 px-4">
                                    <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Implementing solutions</h5>
                                        <p>Finally, we're here to help you bring your solutions to life, providing support and resources throughout the implementation process so that you can make a real impact in your community.</p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Service Start --> */}
                <div class="container-xxl py-5 mt-5">
                    <div class="container">
                        <h1 class="text-primary text-uppercase" style={{ fontSize: "large" }}>// Our Theory Of Change //</h1>
                        <div class="row g-4">
                            <img alt="Theory of change" src={require("../img/new/Our Theory of Chage.jpg")} />
                        </div>
                    </div>
                </div>

            <Footer />
        </div>
    )
}
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


export default function NotFound(){
    return(
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: "url(../img/carousel-bg-2.jpg)"}}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Not Found</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">404</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 class="display-1">404</h1>
                            <h1 class="mb-4">Page Not Found</h1>
                            <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                            <a class="btn btn-primary rounded-pill py-3 px-5" href="/">Go Back To Home</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// The card component that takes image, title, and description as props
const ProgramCard = ({ image, title, description, link }) => {
  const navigate = useNavigate();
  return (
    <Card className="program-card col-md-3 mx-0" onClick={() => {navigate(link)}}>
      <Card.Img variant="top" src={image} />
      {/* <Card.Body> */}
        <Card.Title>{title}</Card.Title>
        {/* <Card.Text>{description}</Card.Text> */}
      {/* </Card.Body> */}
    </Card>
  );
};

// The array of objects that store the data for each card
const programs = [
  {
    image: require("../img/new/Copy of youth (10).png"),
    title: "Join our vibrant learning community.",
    link: "/yocilab-academy"
    // description:
    //   "Create a program to empower, support and welcome less represented groups into the entrepreneurship ecosystem. Guide entrepreneurs from all types of backgrounds as they build their own businesses.",
  },
  {
    image: require("../img/new/8.png"),
    title: "Co-create and bring your ideas to life.",
    link: "/start-up-hive"
    // description:
    //   "Create a program to help founders develop solutions to the most pressing issues of today.",
  },
  {
    image: require("../img/new/5.png"),
    title: "Access funding and resources to scale your ideas.",
    link: "/poolfund"
    // description:
    //   "Create a program to help founders develop solutions to the most pressing issues of today.",
  },
  {
    image: require("../img/new/7.png"),
    title: "Become a partner. Join us in supporting the next generation of emerging leaders.>>>",
    link: "/contact"
    // description:
    //   "Foundation Gloria Kriete trains social entrepreneurs in El Salvador. For 2nd year in a row, it will host 2023 Entrepreneurial Incubator, training 30 entrepreneurs to develop purpose-driven businesses. Selected projects will be part of a comprehensive training program and have chance to apply to \"Ayudando A Quienes Ayudan\" seed capital contest.",
  },
];

// The main component that renders the row of cards
const Programs = () => {
  return (
    <Container fluid className="py-5 px-0 mx-0">
    <div className="row mx-0">
    <h1 class="mb-4"><span class="text-primary">Our</span> Programs</h1>
      {programs.map((program) => (
        <ProgramCard
          key={program.title}
          image={program.image}
          title={program.title}
          description={program.description}
          link={program.link}
        />
      ))}
    </div>
    </Container>
  );
};

export default Programs;

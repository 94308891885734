import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


export default function(){
    return(
        <div>
            <Navbar />

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 class="text-primary text-uppercase">// Contact Us //</h6>
                        <h1 class="mb-5">Contact For Any Query</h1>
                    </div>
                    <div class="row g-4">
                        <div class="col-12">
                            <div class="row gy-4">
                                <div class="col-md-4">
                                    <div class="bg-light d-flex flex-column justify-content-center p-4">
                                        <h5 class="text-uppercase">// Tel //</h5>
                                        <p class="m-0"><i class="fa fa-envelope-open text-primary me-2"></i>+237680192095</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="bg-light d-flex flex-column justify-content-center p-4">
                                        <h5 class="text-uppercase">// General //</h5>
                                        <p class="m-0"><i class="fa fa-envelope-open text-primary me-2"></i>info@yocilab.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe class="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9037.594405455528!2d10.184745315424165!3d5.984582495423664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x105f3f91aa77df8f%3A0x3fa5cd178de426c6!2sMile%204!5e0!3m2!1sen!2snl!4v1683809780301!5m2!1sen!2snl" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                frameborder="0" style={{minHeight: "350px", border:0}} aria-hidden="false"
                                tabindex="0"></iframe>
                        </div>
                        <div class="col-md-6">
                            <div class="wow fadeInUp" data-wow-delay="0.2s">
                                <p class="mb-4">
                                    Whether you come from different backgrounds or communities, our mission is to inspire and support you towards your goals. Join us in transforming your vision into reality and creating a better future for yourself and the world around you. 
                                </p>
                                <form>
                                    <div class="row g-3">
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" id="email" placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <textarea class="form-control" placeholder="Leave a message here" id="message" style={{height: "100px"}}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
import React, { useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InsideYOCILAB from '../components/coworking/InsideCoworking';
import Facilities from '../components/coworking/Facility';
import SocialImpactSpace from '../components/coworking/SocialImpact';
import SocialInnovatorsFellowship from '../components/coworking/SocialInnovatorsFellowship';
import "../css/CoCreationHub.css"

export default function CoCreationHub() {
    const [activeTab, setActiveTab] = useState('officeSpace');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'officeSpace':
                return (
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/private office.jpg")} className="card-img-top" alt="Private Offices" />
                                <div className="card-body">
                                    <h5 className="card-title">Private Offices</h5>
                                    <p className="card-text">Dedicated spaces for teams looking for privacy and focus.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/Day office.jpg")} className="card-img-top" alt="Custom Offices" />
                                <div className="card-body">
                                    <h5 className="card-title">Custom Offices</h5>
                                    <p className="card-text">Tailored office solutions to meet your specific needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/PXL_20230506_143225455.MP.jpg")} className="card-img-top" alt="Day Offices" />
                                <div className="card-body">
                                    <h5 className="card-title">Day Offices</h5>
                                    <p className="card-text">Flexible spaces available for daily use.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/gallery(3).jpg")} className="card-img-top" alt="Office Membership" />
                                <div className="card-body">
                                    <h5 className="card-title">Office Membership</h5>
                                    <p className="card-text">Join our community with flexible membership options.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'coworking':
                return (
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <h3>A 100% Vibrant Youth Co-Working Space</h3>
                            <p>Join YOCILAB's thriving community of young entrepreneurs, innovators, and change-makers. Our creatively designed, open-plan co-working environment enhances the way you work, offering opportunities to collaborate, connect, and create with like-minded individuals.</p>
                            <h4>Facilities include:</h4>
                            <ul>
                                <li>24/7 Access</li>
                                <li>Networking events</li>
                                <li>Library</li>
                                <li>WIFI</li>
                                <li>Tea & Coffee</li>
                                <li>Juices & Snacks available</li>
                                <li>Printing included</li>
                                <li>Entertainments</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/cocreation/present.jpg")} alt="Co-Working Facilities" />
                        </div>
                    </div>
                );
            case 'virtualOffices':
                return (
                    <div className="row g-4">
                        <div className="col-lg-6">
                            <h3>Virtual Co-Working Space</h3>
                            <p>YOCILAB's Virtual Co-Working Space is designed to customize office space for young entrepreneurs and innovators who thrive in a flexible, remote work environment from anywhere in the world. The platform provides a custom office that you can design according to your organization's needs with an AI model that captures in-time special moments, keeps track of your meetings, and offers flexible schedules for your team among many other features.</p>
                            <a href="#" className="btn btn-primary">Sign Up</a>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/cocreation/Gallery(5).jpg")} alt="Virtual Office" />
                        </div>
                    </div>
                );
            case 'meetingRooms':
                return (
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/Board room.jpg")} className="card-img-top" alt="Board Room" />
                                <div className="card-body">
                                    <h5 className="card-title">Board Room</h5>
                                    <p className="card-text">Our meeting room accommodates up to 10 people and is perfect for team meetings, brainstorming sessions, and welcoming new clients. Features a 65-inch screen for presentations, whiteboard, and flip chart.</p>
                                    <a href="#" className="btn btn-primary">Book here!</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/cocreation/conference hall.jpg")} className="card-img-top" alt="Conference Room" />
                                <div className="card-body">
                                    <h5 className="card-title">Conference Room</h5>
                                    <p className="card-text">Our co-creation space includes a mini-conference area that can host 20–25 people, depending on the arrangement. We can also accommodate larger events within the area.</p>
                                    <a href="#" className="btn btn-primary">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="co-creation-hub">
            <Navbar />

            {/* Header Section */}
            {/* <div className="container-fluid page-header mb-5 p-0" style={{background: "url(../img/yocilab/startup_hive.jpg) no-repeat center center", backgroundSize: "cover"}}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">YOCILAB Co-Creation Hub</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Co-Creation Hub</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div> */}

            <header className="hero">
                <div className="hero-content">
                    {/* <h1 className="hero-title">INVESTING IN A BETTER WORLD FOR 35 YEARS</h1>
                    <p className="hero-subtitle">
                        This is a high-performance zone. We are building the largest youth innovation platform
                        and entrepreneurship ecosystem in Africa, one community and one country at a time.
                    </p> */}
                    <h1 class="mb-4 text-primary">YOCILAB Co-Creation Hub</h1>
                    <p class="mb-4 fs-4">
                        Welcome to the YOCILAB Co-Creation Hub, where innovative ideas come to life through collaborative efforts! Our program is designed to empower individuals, startups, and established enterprises to work together,
                    </p>
                </div>
            </header>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src={require("../img/cocreation/co-creation space.jpg")} style={{ objectFit: "cover" }} alt="" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// A 100% Vibrant Youth Co-Working Space //</h6>
                            <h1 class="mb-4"><span class="text-primary">YOCILAB</span> Co-working Space</h1>
                            <p class="mb-4">
                                Join a thriving community of young entrepreneurs, innovators, and change-makers at YOCILAB's shared space. Our creatively designed, open-plan co-working environment enhances the way you work, offering opportunities to collaborate, connect, and create with like-minded individuals.
                            </p>
                            <p class="mb-4">
                                Whether you come from different backgrounds or communities, our mission is to inspire and support you towards your goals. Join us in transforming your vision into reality and creating a better future for yourself and the world around you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <InsideYOCILAB />

            <Facilities />

            <SocialImpactSpace />

            <SocialInnovatorsFellowship />


            {/* Call to Action Section */}
            <div className="container-xxl py-5 bg-light wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-8 col-md-6">
                            <h6 className="text-primary text-uppercase">// Get Involved //</h6>
                            <h1 className="mb-4">Join Our Community</h1>
                            <p className="mb-4">
                                YOCILAB Co-Creation Hub is more than just a physical space; we are fueling the future of entrepreneurship, one idea at a time. Whether you're a social impact investor, business coach, or entrepreneur, join us as we empower the next generation of problem solvers.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="bg-primary d-flex flex-column justify-content-center text-center h-100 p-4">
                                <h3 className="text-white mb-4"><i className="fa fa-phone-alt me-3"></i><a style={{ color: "#fff" }} href="https://wa.me/237680192095">+237680192095</a></h3>
                                <a href="#" className="btn btn-secondary py-3 px-5">Contact Us<i className="fa fa-arrow-right ms-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
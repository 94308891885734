import React from 'react';
import './StatisticsComponent.css'; // You'll need to create this CSS file

const StatisticsComponent = () => {
  return (
    <div className="container my-5 py-5 text-center">
      <h1 className="display-4 mb-5">Here Is Our Why!</h1>

      <div className="row">
        <div className="col-md-4">
          <h6 className="text-uppercase" style={{ color: '#6c757d', letterSpacing: '1px' }}>OVER</h6>
          <h1 className="display-4 font-weight-bold">90%</h1>
          <p className="lead">of youth in underserved communities have limited skills and resources to develop their ideas and penetrate the evolving job market.</p>
        </div>
        <div className="col-md-4">
          <h6 className="text-uppercase" style={{ color: '#6c757d', letterSpacing: '1px' }}>ONLY</h6>
          <h1 className="display-4 font-weight-bold">1 in 25</h1>
          <p className="lead">young people with innovative ideas have access to funding, loans, or angel investments.</p>
        </div>
        <div className="col-md-4">
          <h6 className="text-uppercase" style={{ color: '#6c757d', letterSpacing: '1px' }}>MORE THAN</h6>
          <h1 className="display-4 font-weight-bold">70%</h1>
          <p className="lead">of youth startups fail before their third year of operation.</p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsComponent;
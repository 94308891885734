import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import Gallery from "../components/gallery";

export default function () {
    return (
        <div>
            <Navbar />

            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img//yocilab/IMG-20220909-WA0051.jpg)" }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">Digital Tech Initiative</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Programs</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Digital Tech Initiative</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mt-5">
                <div class="container">
                    <h1 class="text-primary text-uppercase" style={{ fontSize: "large" }}>// Together we //</h1>
                    <div class="row g-4">
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="d-flex py-5 px-4">
                                <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Increase access to education</h5>
                                    <p>
                                        Digitalize 50 schools by creating tech labs and improving access to the internet, ICT, and STEM education for students by 2030.
                                    </p>
                                    <a class="text-secondary border-bottom" href="">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="d-flex bg-light py-5 px-4">
                                <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Unlock potential</h5>
                                    <p>
                                        Create the largest youth digital tech hub in Cameroon that provides free access to ICT and STEM training, resources, tools, and leadership to inspire creativity and innovation among young people.
                                    </p>
                                    <a class="text-secondary border-bottom" href="">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="d-flex py-5 px-4">
                                <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Empower</h5>
                                    <p>
                                        Train 1,000+ ICT and STEM education trainers, resource persons, and ambassadors to champion ICT and STEM education in grassroots communities across Cameroon.
                                    </p>
                                    <a class="text-secondary border-bottom" href="">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5 mb-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <h6 className="text-primary text-uppercase">// The Problem //</h6>
                            <h1 className="mb-4">Did you <span className="text-primary">know?</span></h1>
                            <p className="mb-4">
                                In marginalized communities in Cameroon, only 1 in 10 young people have access to Information and communication technology (ICT) skills and science, Technology, engineering and mathematics (STEM) education tools, and over 45% of young people especially, girls aged 5 to 14 do not know how to turn on a computer. This has created a significant digital divide and unequal educational opportunities for young people across the country, hindering young people’s ability to develop their ideas, access further education opportunities, and develop a skilled workforce capable of driving innovation in this digitally evolving, tech-driven economy.
                            </p>
                        </div>
                        <div className="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img className="position-absolute img-fluid w-100 h-100" src="./img/second/IMG_3207.jpg" style={{ objectFit: "cover" }} alt="" />
                                <div className="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row g-5 mt-5">
                        <div className="col-lg-12">
                            <h1 className="mb-4">We are here to <span className="text-primary">bridge</span> the Gap</h1>
                            <p className="mb-4">
                                You are part of our big family. Set a personal goal, state your mission, and invite your family and friends to donate.
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* What We Do Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <h6 class="text-primary text-center text-uppercase">// We are here to bridge the Gap //</h6>
                    <h1 className="text-uppercase text-center mb-4">What We Do</h1>
                    <p className="mb-4 text-center">
                        You are part of our big family. Set a personal goal, state your mission, and invite your family and friends to donate.
                    </p>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/Copy of youth.png")} className="card-img-top" alt="Digital Companion and Online Safety" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Digital Companion and Online Safety //</h3>
                                    <p className="card-text">
                                        We protect and help young people stay safe online. We have designed programs to educate students, teachers, and parents about responsible online behavior.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/PXL_20240711_092811521.jpg")} className="card-img-top" alt="Girls Tech Camp" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Girls Tech Camp – Bridging the Gender Divide in Tech //</h3>
                                    <p className="card-text">
                                        Our Girls Tech Camp is designed to create more opportunities for young girls from marginalized communities to engage in ICT and STEM training programs. We provide a supportive community that inspires creativity and innovation among young girls.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/IMG-20230412-WA0006.jpg")} className="card-img-top" alt="Digital Tech Center" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Digital Tech Center – A One-Stop Digital Hub //</h3>
                                    <p className="card-text">
                                        Our Digital Tech Center is a one-stop digital hub for young people interested in ICT and STEM training. We have developed this center as an open resource hub that provides youth with resources, tools, and free training on robotics, web development, programming, 3D modeling and printing, virtual reality, cybersecurity, and leadership training programs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/PXL_20230224_105119000.MP~2.jpg")} className="card-img-top" alt="Digital Tech for Education" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Digital Tech for Education – Bringing ICT and STEM to Schools //</h3>
                                    <p className="card-text">
                                        We digitalize, and equip schools with tech lab and experience ICT and STEM trainers, and work with students to design education kits such as gamification and project-based learning to improve learning experiences for students.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/PXL_20240316_093142661.jpg")} className="card-img-top" alt="Hackathons" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Hackathons – Inspiring Innovation //</h3>
                                    <p className="card-text">
                                        YOCILAB organizes hackathons to inspire and support young people in building innovative solutions that address local community challenges, from climate change to hunger, health, and unemployment.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="h-100 shadow-sm border-0">
                                <img src={require("../img/updates1/Entrepreneurship and innovation.jpg")} className="card-img-top" alt="Partnerships" />
                                <div className="card-body">
                                    <h3 className="card-title text-primary">// Partnerships //</h3>
                                    <p className="card-text">
                                        We collaborate with international and local organizations to ensure the sustainability and success of our programs. By working together, we can create a significant impact in transforming lives through digital education and technology.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container text-center">
                    <h2 className="mb-4">Get Involved</h2>
                    <p className="mb-4">
                        Join us in our mission to bridge the digital divide. Your involvement can make a significant impact on the lives of many.
                    </p>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=SHXZ5AGMC67QJ" className="btn btn-primary btn-lg">Donate</a>
                    <a href="#donate-computer" className="btn btn-secondary btn-lg ms-3">Donate a Computer</a>
                </div>
            </div>
            

            {/* <div id="donate" className="container-xxl py-5 bg-light">
                <div className="container text-center">
                    <h2 className="mb-4">Donate</h2>
                    <p className="mb-4">
                        Your generous donation helps us provide essential technology education to those who need it most. Every contribution makes a difference.
                    </p>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=SHXZ5AGMC67QJ" className="btn btn-primary btn-lg" target="_blank" rel="noopener noreferrer">
                        Donate with PayPal
                    </a>
                </div>
            </div> */}

            {/* <div id="donate-computer" className="container-xxl py-5">
                <div className="container text-center">
                    <h2 className="mb-4">Donate a Computer</h2>
                    <p className="mb-4">
                        Help us equip young people with the necessary tools by donating new or used laptops and computers. Your old device can become a powerful tool for learning and development.
                    </p>
                    <a href="#contact" className="btn btn-secondary btn-lg">Contact Us to Donate</a>
                </div>
            </div> */}

            <div className="container-xxl py-5 bg-light">
                <div className="container text-center">
                    <h2 className="mb-4">Even Better</h2>
                    <p className="mb-4">
                        Take your commitment to the next level. You are part of our big family. Set a personal goal, state your mission, and invite your family and friends to donate. Launch your campaign and make a bigger impact.
                    </p>
                    <a href="/launch" className="btn btn-primary btn-lg">Launch Your Campaign</a>
                </div>
            </div>

            <Gallery />




            <Footer />
            <Outlet />
        </div>
    );
}

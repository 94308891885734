import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(){
    return(
        <>
        <div class="container-fluid bg-light p-0">
            <div class="row gx-0 d-none d-lg-flex">
                <div class="col-lg-7 px-5 text-start">
                    <div class="h-100 d-inline-flex align-items-center py-3 me-4">
                        <small class="fa fa-map-marker-alt text-primary me-2"></small>
                        <small>Mile 4, Bamenda, Cameroon</small>
                    </div>
                    <div class="h-100 d-inline-flex align-items-center py-3">
                        <small class="far fa-clock text-primary me-2"></small>
                        <small>Mon - Fri : 09.00 AM - 09.00 PM</small>
                    </div>
                </div>
                <div class="col-lg-5 px-5 text-end">
                    <div class="h-100 d-inline-flex align-items-center">
                        <a class="btn btn-sm-square bg-white text-primary me-1" href="https://web.facebook.com/Yocilab"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-sm-square bg-white text-primary me-1" href=""><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-sm-square bg-white text-primary me-1" href="https://www.linkedin.com/company/youth-connect-innovation-lab/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-sm-square bg-white text-primary me-0" href="https://www.instagram.com/yocilab/"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
            <a href="/" class="navbar-brand d-flex align-items-center px-4 px-lg-5">
                <img
                    style={{color: "red"}}
                    src="../img/YOCILAB-01.svg" height="100%" width="100" />
            </a>
            <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto p-4 p-lg-0">
                    <a href="/" class="nav-item nav-link" id="home">Home</a>
                    <div class="nav-item dropdown">
                        <a href="/about" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">About</a>
                        <div class="dropdown-menu fade-up m-0">
                            <a href="/about" class="dropdown-item">About Yocilab</a>
                            <a href="/approach" class="dropdown-item">Our Approach</a>
                            {/* <a href="/about/team" class="dropdown-item">Our Team</a> */}
                        </div>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="/dit" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Programs</a>
                        <div class="dropdown-menu fade-up m-0">
                            {/* <a href="/dti" class="dropdown-item">Digital Tech Initiative</a> */}
                            {/* <Link to={{ pathname: "green-space-academy.com" }} target="_blank" class="dropdown-item" >
                            Green Space Academy
                            </Link> */}
                            <a href="/yocilab-academy" class="dropdown-item">YOCILAB ACADEMY</a>
                            <a href="/co-creation-space" class="dropdown-item">YOCILAB Co-Creation hub</a>
                            <a href="/seed4impactfund" class="dropdown-item">Seed4ImpactFund</a>
                        </div>
                    </div>
                    <a href="/dti" class="nav-item nav-link">Digital Tech Initiative</a>
                    {/* <a href="/seed4impactfund" class="nav-item nav-link">Seed4ImpactFund</a> */}
                    {/* <a href="/poolfund" class="nav-item nav-link">PoolFund</a> */}
                    {/* <a href="/greenspace" class="nav-item nav-link">Green Space Academy</a> */}
                    {/* <a href="/inside" class="nav-item nav-link">Inside Yocilab</a> */}
                    {/* <a href="/blog" class="nav-item nav-link">Blog</a> */}
                    <a href="/contact" class="nav-item nav-link">Contact</a>
                </div>
            </div>
        </nav>
        </>
    )
}
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ({blog}) {
    const navigate = useNavigate()

    const handleClick = (event) => {
        event.preventDefault()
        navigate('/blog-content', { state: blog });
      };

    return (
        <div class="col-xs-12 col-sm-6 col-md-3 mx-5 card">
            <div class="card-header">
                <img src={blog.photo} alt={blog.header} />
            </div>
            <div class="card-body">
                <span class="tag tag-teal">{blog ? blog.tag : "Yocilab"}</span>
                <h4>
                    <a href="#" onClick={handleClick}>{blog.title}</a>
                </h4>
                <p>
                    {blog.hook}
                </p>
            </div>
        </div>
    )
}
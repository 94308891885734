import React from 'react';
import './WhatWeDo.css';

const WhatWeDo = () => {
  return (
    <div class="what-we-do-section">
      <h1 class="section-title">What We Do</h1>

      <div class="cards-container">
        <div class="card">
          <div class="card-image-container">
            <img src={require("../../img/second/home/YOCILAB Academy.jpg")} alt="Education and Youth Employability"/>
          </div>
          <div class="card-content">
            <h3>Education and Youth Employability – Bridging the Gap</h3>
            <p>
              YOCILAB Academy provides skill training, access to technology and STEM programs, professional development and talent match-up, resource database, with focus to bridge the gap between education and the evolving job market. We engage young people through project-based learning, mentorship, vocational training, and entrepreneurship combined with digital skills.
            </p>
            <a href="#" class="btn learn-more-btn btn-primary">Learn More</a>
          </div>
        </div>

        <div class="card">
          <div class="card-image-container">
            <img src={require("../../img/second/home/co-creation Space.jpg")} alt="Inspiring Innovation and the Birth of Youth Startups"/>
          </div>
          <div class="card-content">
            <h3>Inspiring Innovation and the Birth of Youth Startups</h3>
            <p>
              YOCILAB Co-Creation Hub provides a vibrant, supportive community and a home for the brightest minds and bold ideas to thrive. We offer 360-degree support to young entrepreneurs and community leaders to build high-impact initiatives. Our entrepreneurs have access to co-working spaces, high-standard events, masterclasses, and other entrepreneurship development programs.
            </p>
            <a href="#" class="btn learn-more-btn btn-primary">Learn More</a>
          </div>
        </div>

        <div class="card">
          <div class="card-image-container">
            <img src={require("../../img/second/home/Seed4Impact Fund.jpg")} alt="Access to Funding and Resources"/>
          </div>
          <div class="card-content">
            <h3>Access to Funding and Resources</h3>
            <p>
              We have designed the SEED4Impact Fund as a $1 million+ revolving investment scheme (Youth innovation bank) that will provide seed investments to 400 youth social enterprises, innovations, and initiatives within the YOCILAB community by 2030. We are currently upgrading our platform to connect youth entrepreneurs with investors and to manage investments in youth startups within our ecosystem.
            </p>
            <a href="#" class="btn learn-more-btn btn-primary">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
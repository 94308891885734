import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './pages/About';
import DigitalTechInitiative from './pages/DigitalTechInitiative';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PoolFund from './pages/PoolFund';
import InsideYocilab from './pages/InsideYocilab';
import Contact from './pages/Contact';
import NotFound from './pages/404';
import CreateBlog from './components/blogs/create';
import BlogslistView from './components/BlogList';
import BlogView from './components/blogs/show';
import BlogEdit from './components/blogs/edit';
import YocilabAcademy from './pages/YocilabAcademy';
import RedirectPage from './pages/RedirectPage';
import StartUpHive from './pages/StartUpHive';
import SocialImpact from './pages/SocialImpact';
import Seed4ImpactFund from './pages/Seed4ImpactFund';
import BlogPage from './pages/BlogPage';
import Approach from './pages/Approach';
import Team from './pages/Team';
import Donate from './pages/DonatPage';
import LaunchCampaign from './pages/LaunchCampaign';
import HomePage from './pages/HomePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFound />
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/approach",
    element: <Approach />,
  },
  {
    path: "/donate",
    element: <Donate />,
  },
  {
    path: "/launch",
    element: <LaunchCampaign />,
  },
  {
    path: "/about/team",
    element: <Team />
  },
  {
    path: "/blog-content",
    element: <BlogPage />,
  },
  {
    path: "/dti",
    element: <DigitalTechInitiative />,
  },
  {
    path: "/poolfund",
    element: <PoolFund />,
  },
  {
    path: "/inside",
    element: <InsideYocilab />,
  },
  {
    path: "/yocilab-academy",
    element: <YocilabAcademy />,
  },
  {
    path: "/co-creation-space",
    element: <StartUpHive />,
  },
  {
    path: "/social-impact",
    element: <SocialImpact />,
  },
  {
    path: "/greenspace",
    element: <RedirectPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/blog",
    element: <BlogslistView />,
  },
  {
    path: "/create",
    element: <CreateBlog />,
  },
  {
    path: "/show/:id",
    element: <BlogView />,
  },
  {
    path: "/editblog/:id",
    element: <BlogEdit />,
  },
  {
    path: "/seed4impactfund",
    element: <Seed4ImpactFund />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React , {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import fb from "../firebase";
import Navbar from "./Navbar";
import Footer from "./Footer";

import Card from "./Card";
import "./BlogsList.css"

const DB =fb.firestore()
const Blogslist = DB.collection('blogs');

const BlogslistView = () => {
    const[blogs, Setblogs] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() =>{
        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
              ...doc.data(),
              id: doc.id,
            }));
            // Update state
            Setblogs(data);
            setLoading(false)
          });
  
          // Detach listener
          return unsubscribe;
    }, []);

    const DeleteBlog = (id)=> {
        Blogslist.doc(id).delete().then(() => {
            alert("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };
    return(
        <div>
        <Navbar />
        {loading && <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto"
            }}>
                Loading blog posts
            </div>}
        <div
            className="py-5 cont"
        >
            {blogs.map(blog => (
                <Card 
                    key={blog.id} 
                    title={blog.Title}
                    text={blog.Body}
                    linkv={"/show/" + blog.id}
                    linke={"/editblog/" + blog.id}
                    id={blog.id}
                    DeleteBlog={DeleteBlog}
                    image={blog.image}
                />
            ))}
        </div>

        <Footer add={true} />
        </div>
    );
};

export default BlogslistView;
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from 'firebase/storage';

firebase.initializeApp({
    apiKey: "AIzaSyDPN0G3yDPoyK-Kr41j-_mX0KvHPkYfUds",
    authDomain: "pa-oi-v2.firebaseapp.com",
    projectId: "pa-oi-v2",
    storageBucket: "pa-oi-v2.appspot.com",
    messagingSenderId: "867085982854",
    appId: "1:867085982854:web:e48ecd032dcedcf8e7c507"
});

const fb = firebase;

export default fb;
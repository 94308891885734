import React from 'react';
import './InsideYOCILAB.css';

const InsideYOCILAB = () => {
  return (
    <div className="inside-yocilab-section">
      <h1 className="section-title">Inside YOCILAB Co-Working Space</h1>
      
      <div className="cards-container">
        {/* Day Office Card */}
        <div className="card">
          <div className="card-image-container">
            <img src={require("../../img/cocreation/Day office.jpg")} alt="Day Office" />
          </div>
          <div className="card-content">
            <h3>Day Office</h3>
            <p>
              Dive in and get some work done! Our day office is available 24/7 for teams and individuals looking to book temporary or permanent desks. Plus, our technical support team ensures everything runs smoothly, allowing you and your team to focus on achieving your best work.
            </p>
            <a href="#" className="btn  btn-primary">Reserve your spot now!</a>
          </div>
        </div>

        {/* Board Room Card */}
        <div className="card">
          <div className="card-image-container">
            <img src={require("../../img/cocreation/Board room.jpg")} alt="Board Room" />
          </div>
          <div className="card-content">
            <h3>Board Room</h3>
            <p>
              Our meeting room accommodates up to 10 people and is perfect for team meetings, brainstorming sessions, and welcoming new clients. The board room features a 65-inch screen for presentations, a whiteboard, flip chart, and other essential accessories.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Book here!</a>
          </div>
        </div>

        {/* Conference Room Card */}
        <div className="card">
          <div className="card-image-container">
            <img src={require("../../img/cocreation/conference hall.jpg")} alt="Conference Room" />
          </div>
          <div className="card-content">
            <h3>Conference Room</h3>
            <p>
              Our co-creation space includes a mini-conference area that can host 20–25 people, depending on the arrangement. We can also accommodate larger events within the area. Get in touch with your requirements, and let our team work their magic!
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Book Now</a>
          </div>
        </div>

        {/* Private Office Space Card */}
        <div className="card">
          <div className="card-image-container">
            <img src={require("../../img/cocreation/private office.jpg")} alt="Private Office Space" />
          </div>
          <div className="card-content">
            <h3>Private Office Space</h3>
            <p>
              Currently, YOCILAB Co-Creation Space does not offer private office spaces. However, we recognize the increasing demand for private work environments. We are actively working to secure a larger location that can accommodate private offices. Stay connected for updates.
            </p>
          </div>
        </div>

        {/* Virtual Co-Working Space Card */}
        <div className="card">
          <div className="card-image-container">
            <img src={require("../../img/cocreation/co-creation space.jpg")} alt="Virtual Co-Working Space" />
          </div>
          <div className="card-content">
            <h3>Virtual Co-Working Space</h3>
            <p>
              Welcome to our Virtual Office, where innovation meets flexibility! Our platform transforms the traditional workspace into a dynamic, digital environment tailored to your needs. With customizable features, you can design your office layout, set up collaborative spaces, and create a productive atmosphere that reflects your organization’s culture. Our cutting-edge AI technology enhances your experience by capturing real-time special moments, managing your meetings, and optimizing your team’s schedule.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Sign Up</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsideYOCILAB;

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";

export default function () {
    return (
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{ backgroundImage: require("../img/team/cover.jpg") }}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Seed4ImpactFund</h1>
                        <p className="mt-2 mb-2" style={{ color: "#fff" }}>Great and innovative ideas deserve life</p>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Programs</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Seed4ImpactFund</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row g-4">
                        <div class="col-lg-8 col-md-6">
                            <h6 class="text-primary text-uppercase">// Why the seed4impactfund //</h6>
                            <h1 class="mb-4">About</h1>
                            <p class="mb-4">
                                The Seed4Impact fund is a revolving impact investment scheme that invests in and supports high-impact youth startups and social enterprises within the YOCILAB Co-creation hub.
                            </p>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="bg-primary d-flex flex-column justify-content-center text-center h-100 p-4">
                                <h3 class="text-white mb-4"><i class="fa fa-phone-alt me-3"></i><a style={{ color: "#fff" }} href="https://wa.me/237680192095">+237680192095</a></h3>
                                <a href="" class="btn btn-secondary py-3 px-5">Contact Us<i class="fa fa-arrow-right ms-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row g-4">
                    <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src={require("../img/new/6.png")} style={{ objectFit: "cover" }} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            {/* <h6 class="text-primary text-uppercase">// Why the seed4impactfund //</h6> */}
                            <h1 class="mb-4">Goal</h1>
                            <p class="mb-4 mt-4">
                                <span className="text-primary">By 2030</span>, Seed4Impact Fund aims to raise $1 million to be invested in 100 youth startups and entrepreneurs in underserved communities in Cameroon.
                            </p>
                            <p class="mb-0">
                                Only 1 in 25 youth with innovative ideas in underserved communities across sub-Saharan Africa has the capital or access to funding to bring their ideas to life. Seed4Impact Fund exists because young people have ideas, and these ideas, whether good or bad, unfortunately die if not given life.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mt-5">
                <div class="container">
                    {/* <!-- <h1 class="text-primary text-uppercase" style="font-size: large;">// Together we //</h1> --> */}
                    <div class="row g-4">
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="d-flex py-5 px-4">
                                <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Impact Investment</h5>
                                    <p>
                                        Seed4Impact Fund, through a revolving scheme, provides zero-interest investment loans to entrepreneurs and startups within the YOCILAB Co-creation lab.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div class="d-flex bg-light py-5 px-4">
                                <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Connect Funders and Investors</h5>
                                    <p>
                                        We bridge the gap between entrepreneurs and investors by connecting youth-led social enterprises and purpose-driven businesses with potential investors who are passionate about making a positive impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="d-flex py-5 px-4">
                                <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                                <div class="ps-4">
                                    <h5 class="mb-3">Investment Management</h5>
                                    <p>
                                        Seed4Impact Fund manages impact investments on behalf of funders and investors. We carefully evaluate potential investment opportunities, ensuring that they align with our mission and have the potential for significant social and environmental impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Impact Measurement //</h6>
                            <h1 class="mb-4">Impact Measurement and <span class="text-primary">Evaluation</span></h1>
                            <p class="mb-4">
                                We are committed to measuring and evaluating the impact of our investments. Through rigorous monitoring and evaluation processes, we track the progress of the businesses we support, ensuring that they are making a meaningful difference in their communities.
                            </p>
                            <p class="mb-4">
                                Join us in supporting youth with great and innovative ideas. Together, we can make a lasting impact.
                            </p>
                        </div>
                        <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src={require("../img/team/seed4impact.jpg")} style={{ objectFit: "cover" }} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                    {/* <!-- <h1 class="display-4 text-white mb-0">5 <span class="fs-4">Years</span></h1>
                                <h4 class="text-white">Experience</h4> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid bg-secondary booking my-5 wow fadeInUp" id="form1" data-wow-delay="0.1s">
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-lg-6 py-5">
                            <div class="py-5">
                                <h1 class="text-white mb-4">Seed4Impact</h1>
                                <p class="text-white mb-0">
                                    Donate a used or new laptop, tablet, networking equipment, surplus computers or other electronic gadget and join us on the front lines of bridging computer literacy gap to build a brighter, and more equitable future for young people in marginalized communities across Cameroon and beyond.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="bg-primary h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
                                <h1 class="text-white mb-4">Want To Help</h1>
                                <form>
                                    <div class="row g-3">
                                        <div class="col-12 col-sm-6">
                                            <input type="text" class="form-control border-0" placeholder="Your Name" style={{ height: "55px" }} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input type="email" class="form-control border-0" placeholder="Your Email" style={{ height: "55px" }} />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <select class="form-select border-0" style={{ height: "55px" }}>
                                                <option selected>Select A Device</option>
                                                <option value="1">Laptop</option>
                                                <option value="2">Desktop</option>
                                                <option value="3">Server</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="date" id="date1" data-target-input="nearest">
                                                <input type="text"
                                                    class="form-control border-0 datetimepicker-input"
                                                    placeholder="Date" data-target="#date1" data-toggle="datetimepicker" style={{ height: "55px" }} />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control border-0" placeholder="Special Request"></textarea>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-secondary w-100 py-3" type="submit">Contact Us</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import TeamCarousel from "../components/TeamCarousel"
import Staff from "../components/Staff"

export default function Team() {
    return (
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img//yocilab/IMG-20220909-WA0051.jpg)" }}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Team</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Team</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <TeamCarousel />
            <Staff />

            <Footer />
        </div>
    )
}
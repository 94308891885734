import React from 'react';
import './OurImpact.css';

const OurImpact = () => {
  return (
    <div class="our-impact-section">
      <h1 class="section-title">Our Impact</h1>

      <div class="impact-stats">
        <div class="impact-item">
          <h2>2+</h2>
          <p>Countries</p>
        </div>
        <div class="impact-item">
          <h2>278</h2>
          <p>Entrepreneurs and Community Leaders</p>
        </div>
        <div class="impact-item">
          <h2>69</h2>
          <p>High-Impact Projects and Events</p>
        </div>
        <div class="impact-item">
          <h2>3</h2>
          <p>Key Programs</p>
        </div>
        <div class="impact-item">
          <h2>26,756</h2>
          <p>People Impacted</p>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
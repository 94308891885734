import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Blog({blog}) {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h2 className="text-center mb-5">{blog.title}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <img src={blog.photo} alt="Blog Post" className="img-fluid" />
        </Col>
        <Col md={6}>
        {blog.content.map((content, index) => <p key={index}>{content}</p>)}
        </Col>
      </Row>
    </Container>
  );
}

export default Blog;

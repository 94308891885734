import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { FaBullhorn, FaArrowRight, FaRegSmileWink } from 'react-icons/fa';

const LaunchCampaign = () => {
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    return (
        <div className="launch-campaign-page">
            <Container className="py-5">
                <Row className="text-center mb-5">
                    <Col>
                        <h1 className="display-4">Launch Your Campaign</h1>
                        <p className="lead">Take your commitment to the next level. Set a personal goal, state your mission, and invite your family and friends to donate.</p>
                        <FaBullhorn size={50} className="text-primary mb-3" />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Form onSubmit={handleSubmit} className="p-4 shadow rounded bg-light">
                            <Form.Group className="mb-3" controlId="campaignName">
                                <Form.Label>Campaign Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your campaign name" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="campaignGoal">
                                <Form.Label>Campaign Goal</Form.Label>
                                <Form.Control type="number" placeholder="Enter your campaign goal in USD" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="campaignDescription">
                                <Form.Label>Campaign Description</Form.Label>
                                <Form.Control as="textarea" rows={5} placeholder="Describe your campaign and mission" required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="campaignEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" placeholder="Enter your email address" required />
                            </Form.Group>
                            <div className="text-center">
                                <Button variant="primary" type="submit" className="mt-3">
                                    Launch Campaign <FaArrowRight className="ms-2" />
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Campaign Launched!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <FaRegSmileWink size={50} className="text-success mb-3" />
                        <p>Your campaign has been successfully launched. We wish you the best of luck!</p>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    );
};

export default LaunchCampaign;

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


export default function(){
    return(
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{backgroundImage: "url(../img/yocilab/Impact\ Space\ cover\ .jpg)"}}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">Social Impact Space</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">Social Impact Space</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 pt-4" style={{minHeight: "400px"}}>
                            <div class="position-relative h-100 wow fadeIn p-0" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/second/Kim media.jpg" style={{objectFit: "cover"}} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                                    <h1 class="display-4 text-white mb-0"></h1>
                                    <h4 class="text-white"></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Impact Space //</h6>
                            <h2 class="mb-4">
                                Get inspired by real stories from entrepreneurs and ChangeMaers in action. Behind the Scenes Insights You Won't Find Anywhere Else
                            </h2>
                            <p class="mb-4">
                                Are you ready to be inspired by some of the most highly acclaimed entrepreneurs and changemakers in the game?
                            </p>
                            <p class="mb-4">
                                At the Social Impact Space, we bring together the best and brightest for a series of bi-weekly talk shows and a monthly podcast. Each episode features different guests who shares their entrepreneurship journey - the ups, the downs, the challenges and the successes - with a group of young entrepreneurs and change leader’s eager to learn from the best.
                            </p>
                            <p class="mb-4">
                                So come join us at the Social Impact Space and discover the secrets to entrepreneurial success from those who know it best!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Beyond The Hustle  //</h6>
                            <p class="mb-4">
                                Did you know that running a business can take a toll on mental health? At Youth Connect Innovation Lab, we understand the importance of support and sharing experiences to help ChangeMakers overcome challenges and thrive. That's why our Beyond the Hustle Podcast dives deep into the real struggles of entrepreneurs, including failures, burnout, and anxiety. 
                            </p>
                            <p class="mb-4">
                                Join us as we explore mindful techniques and strategies to help you succeed in your journey as a ChangeMaker.
                            </p>
                        </div>
                        <div class="col-lg-6 pt-4" style={{minHeight: "400px"}}>
                            <div class="position-relative h-100 wow fadeIn p-0" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/yocilab/2348154580684_status_bd2c60d45f104f3a9ddfc9a6b2ddecf5.jpg" style={{objectFit: "cover"}} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{background: "rgba(0, 0, 0, .08)"}}>
                                    <h1 class="display-4 text-white mb-0"></h1>
                                    <h4 class="text-white"></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
import React from 'react';
import './Facility.css';

const Facilities = () => {
  return (
    <div className="facilities-section">
      <h2 className="section-title">YOCILAB Co-Working Space Facilities</h2>

      <div className="facilities-container">
        <div className="facility-card">
          <i className="facility-icon fas fa-clock"></i>
          <p>24/7 Access</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-users"></i>
          <p>Networking Events</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-book"></i>
          <p>Library</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-wifi"></i>
          <p>WIFI</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-coffee"></i>
          <p>Tea & Coffee</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-utensils"></i>
          <p>Juices & Snacks Available</p>
        </div>
        <div className="facility-card">
          <i className="facility-icon fas fa-print"></i>
          <p>Printing Included</p>
        </div>
        {/* <div className="facility-card">
          <i className="facility-icon fas fa-gamepad"></i>
          <p>Entertainments</p>
        </div> */}
      </div>
    </div>
  );
};

export default Facilities;

import React from "react";

export default function Footer(){
    return(
        <div class="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-light mb-4">Address</h4>
                        <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Mile 4, Bamenda, Cameroon</p>
                        <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+237 </p>
                        <p class="mb-2"><i class="fa fa-envelope me-3"></i>info@yocilab.com</p>
                        <div class="d-flex pt-2">
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-light mb-4">Opening Hours</h4>
                        <h6 class="text-light">Monday - Friday:</h6>
                        <p class="mb-4">09.00 AM - 09.00 PM</p>
                        <h6 class="text-light">Saturday - Sunday:</h6>
                        <p class="mb-0">09.00 AM - 12.00 PM</p>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-light mb-4">Programs</h4>
                        <a class="btn btn-link" href="/yocilab-academy">YOCILAB Academy</a>
                        <a class="btn btn-link" href="/co-creation-space">YOCILAB Co-creation Hub</a>
                        <a class="btn btn-link" href="">Seed4Impact Fund</a>
                        {/* <a class="btn btn-link" href="">Social Impact Space</a> */}
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h4 class="text-light mb-4">Newsletter</h4>
                        <p>Join Our Newsletter</p>
                        <div class="position-relative mx-auto" style={{maxWidth: "400px"}}>
                            <input class="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
                            <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                        </div>
                        <div class="position-relative mx-auto">
                        <button class="btn pmd-btn-fab pmd-ripple-effect btn-light pmd-btn-raised" type="button"><a href="/create" class="material-icons pmd-sm">
                            add</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import './SocialImpactSpace.css';

const SocialImpactSpace = () => {
  return (
    <div className="social-impact-section">
      <h1 className="section-title">Social Impact Space</h1>
      <p className="section-description">
        Our Social Impact Space is dedicated to fostering collaboration, innovation, and community engagement. We host a variety of events designed to inspire and empower young leaders to tackle social challenges.
      </p>
      
      <div className="events-container">
        {/* Monthly Co-Creation Lab */}
        <div className="event-card">
          <div className="event-image-container">
            <img src={require("../../img/cocreation/PXL_20230506_143225455.MP.jpg")} alt="Monthly Co-Creation Lab" />
          </div>
          <div className="event-content">
            <h3>Monthly Co-Creation Lab</h3>
            <p>
              We host monthly hands-on workshops focused on developing innovative solutions to social issues. Participants engage in networking, brainstorming sessions, master classes, book readings, design thinking exercises, and project planning.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Sign up</a>
          </div>
        </div>

        {/* Hackathons */}
        <div className="event-card">
          <div className="event-image-container">
            <img src={require("../../img/cocreation/Gallery(5).jpg")} alt="Hackathons" />
          </div>
          <div className="event-content">
            <h3>Hackathons</h3>
            <p>
              We host a quarterly hackathon to inspire innovation and creation among young people. Over the course of 48 hours, teams will brainstorm, design, and prototype their ideas, culminating in presentations to a panel of judges. Join us for the next hackathon between October and November 2024.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Sign up</a>
          </div>
        </div>

        {/* Afrikan Stars of Change */}
        <div className="event-card">
          <div className="event-image-container">
            <img src={require("../../img/cocreation/Gallery(6).jpg")} alt="Afrikan Stars of Change" />
          </div>
          <div className="event-content">
            <h3>Afrikan Stars of Change</h3>
            <p>
              Get inspired by real stories from entrepreneurs and changemakers in action. We host bi-weekly podcasts and a monthly magazine to share stories of young people transforming their local communities and shaping the future of Africa.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Share your story</a>
            <a href="#" className="btn learn-more-btn btn-secondary">Subscribe here</a>
          </div>
        </div>

        {/* Pitch Friday */}
        <div className="event-card">
          <div className="event-image-container">
            <img src={require("../../img/cocreation/gallery(1).jpg")} alt="Pitch Friday" />
          </div>
          <div className="event-content">
            <h3>Pitch Friday</h3>
            <p>
              We host Pitch Friday every two months, providing a platform for entrepreneurs and young innovators to present their social innovation projects to a panel of judges and an audience. Receive feedback and the chance to win funding for your project.
              <br /><strong>Location:</strong> YOCILAB Co-Creation Hub
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Sign up</a>
          </div>
        </div>

        {/* Community Impact Days */}
        <div className="event-card">
          <div className="event-image-container">
            <img src={require("../../img/cocreation/gallery(2).jpg")} alt="Community Impact Days" />
          </div>
          <div className="event-content">
            <h3>Community Impact Days</h3>
            <p>
              Engage in hands-on community service projects that address local needs. This is a chance to put your skills to use while making a positive difference in the community.
            </p>
            <a href="#" className="btn learn-more-btn btn-primary">Join the next community impact day</a>
          </div>
        </div>
      </div>

      <div className="stay-connected">
        <h3>Stay Connected</h3>
        <p>
          To stay updated on all our events and initiatives, subscribe to our newsletter or follow us on social media. Together, we can drive meaningful change!
        </p>
        <a href="#" className="btn learn-more-btn btn-primary">Join our community</a>
      </div>
    </div>
  );
};

export default SocialImpactSpace;

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import images from '../../imageUtils';
import './Gallery.css';

const Gallery = () => {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setCurrentImage(image);
    setShow(true);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Gallery</h2>
      <div className="row">
        {Object.keys(images).map((key, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="gallery-item" onClick={() => handleShow({ src: images[key], alt: key })}>
              <img src={images[key]} alt={key} className="img-fluid" />
            </div>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{currentImage.alt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={currentImage.src} alt={currentImage.alt} className="img-fluid" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Gallery;

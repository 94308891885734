import React from 'react';
import './JoinCommunity.css';

const JoinCommunity = () => {
  return (
    <div className="community-join-section mt-5">
      <div className="container">
        <div className="row">
          <h2 className="section-title text-center py-5">Join Our Community</h2>
          <div className="left-column">
            {/* <h2 className="membership-title"></h2> */}
            <form className="membership-form">
              <input type="text" placeholder="Name" required />
              <input type="text" placeholder="Phone Number" required />
              <input type="email" placeholder="Email" required />
              <button type="submit">Subscribe</button>
            </form>
            <p className="privacy-note">We'll never share your email or spam you</p>
          </div>
          {/* Right Column for social media and other info */}
          <div className="right-column">
            <div className="social-icons">
              <a href="https://facebook.com" className='fa fa-facebook text-primary me-2' target="_blank" rel="noopener noreferrer"></a>
              <a href="https://twitter.com" target="_blank" className='fa fa-twitter text-primary me-2' rel="noopener noreferrer"></a>
              <a href="https://instagram.com" target="_blank" className='fa fa-instagram text-primary me-2' rel="noopener noreferrer"></a>
              <a href="https://linkedin.com" target="_blank" className='fa fa-linkedin text-primary me-2' rel="noopener noreferrer"></a>
            </div>
            <div className="membership-info">
              <p><strong>Explore YOCILAB</strong></p>
              <p>Membership: 0 | Mentors: 0 | Events: 0</p>
            </div>
            <p className="footer-note">© YOCILAB 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import TeamCarousel from "../components/TeamCarousel"
import Staff from "../components/Staff"
import { Carousel } from "react-bootstrap"
import { blogs } from "../constants"
import BlogCard from "../components/BlogCard"

export default function About() {
    return (
        <div>
            <Navbar />

            <div class="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img//yocilab/IMG-20220909-WA0051.jpg)" }}>
                <div class="container-fluid page-header-inner py-5">
                    <div class="container text-center">
                        <h1 class="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center text-uppercase">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Pages</a></li>
                                <li class="breadcrumb-item text-white active" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/second/about us.jpg" style={{ objectFit: "cover" }} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                    {/* <!-- <h1 class="display-4 text-white mb-0">5 <span class="fs-4">Years</span></h1> */}
                                    {/* <h4 class="text-white">Experience</h4> --> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Our Mission //</h6>
                            {/* <!-- <h1 class="mb-4"><span class="text-primary">Who</span> Are YOCILAB</h1> --> */}
                            <p class="mb-4">
                                Our mission is to eliminate barriers and provide equal opportunities for all young people to unleash their creativity and innovation.
                            </p>
                            <p class="mb-4">
                                Whether you come from different backgrounds or communities, our mission is to inspire and support you towards your goals. Join us in transforming your vision into reality and creating a better future for yourself and the world around you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5 mb-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <h6 class="text-primary text-uppercase">// Our Vision //</h6>
                            {/* <!-- <h1 class="mb-4"><span class="text-primary">Who</span> Are YOCILAB</h1> --> */}
                            <p class="mb-4">
                                Empower 5 million African youth with opportunities for success by 2030.
                            </p>
                            <p class="mb-4">
                                Imagine a world where 5 million young people in Africa are equipped with the tools they need to achieve their full potential - where opportunities, resources, and skills are only a click or a step away! At our Youth Connect Innovation Lab, we're all about making this vision a reality.
                                Join us and let's build a brighter future, one young innovator at a time!
                            </p>
                        </div>
                        <div class="col-lg-6 pt-4" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100 wow fadeIn" data-wow-delay="0.1s">
                                <img class="position-absolute img-fluid w-100 h-100" src="./img/second/image_4494eb23-aa00-4436-9932-813623d55b4b20230127_122928.jpg" style={{ objectFit: "cover" }} alt="" />
                                <div class="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5" style={{ background: "rgba(0, 0, 0, .08)" }}>
                                    {/* <!-- <h1 class="display-4 text-white mb-0">5 <span class="fs-4">Years</span></h1>
                        <h4 class="text-white">Experience</h4> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Story End --> */}

                {/* <!-- Service Start --> */}
                <div class="container-xxl py-5 mt-5">
                    <div class="container">
                        <h1 class="text-primary text-uppercase" style={{ fontSize: "large" }}>// Our Believes //</h1>
                        <div class="row g-4">
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="d-flex py-5 px-4">
                                    <i class="fa fa-certificate fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Inspiration:</h5>
                                        <p>
                                            We believe in the potential of young people to effectuate sustainable change, and we strive to empower the next generation of African changemakers.
                                        </p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div class="d-flex bg-light py-5 px-4">
                                    <i class="fa fa-users-cog fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Support</h5>
                                        <p>
                                            We assist young people in developing their self-assurance and making their aspirations a reality.
                                        </p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div class="d-flex py-5 px-4">
                                    <i class="fa fa-tools fa-3x text-primary flex-shrink-0"></i>
                                    <div class="ps-4">
                                        <h5 class="mb-3">Impact</h5>
                                        <p>We endeavor to foster long-term, positive change in the community</p>
                                        {/* <!-- <a class="text-secondary border-bottom" href="">Read More</a> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TeamCarousel />
            <Staff />

            <div class="container-xxl service py-5">
                {/* <div class="container"> */}
                <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h6 class="text-primary text-uppercase">// blog //</h6>
                    <h1 class="mb-5">Inside Yocilab</h1>
                </div>
                {/* <div className='row fadeInUp' data-wow-delay="0.1s"> */}
                <Carousel>
                    {blogs.map((blog, index) => {
                        if (index % 3 === 0) {
                            return (
                                <Carousel.Item key={index}>
                                    <div className="row">
                                        <BlogCard blog={blog} />
                                        {blogs[index + 1] && <BlogCard blog={blogs[index + 1]} />}
                                        {blogs[index + 2] && <BlogCard blog={blogs[index + 2]} />}
                                    </div>
                                </Carousel.Item>
                            );
                        }
                    })}
                </Carousel>

                {/* </div> */}
                {/* </div> */}
            </div>

            <Footer />
        </div>
    )
}